export default {
  review_status: 'minmax(10rem, 1fr)',
  name_of_legal_entity: 'minmax(15rem, 8fr)',
  categorization_name: 'minmax(15rem, 8fr)',
  name: 'minmax(15rem, 8fr)',
  number: 'minmax(15rem, 4fr)',
  submitted_at: 'minmax(15rem, 4fr)',
  voting_at: 'minmax(15rem, 4fr)',
  comment: 'minmax(15rem, 6fr)',
  tooltip: '1fr',
  request_number: 'minmax(15rem, 4fr)',
  voting_commissioner: 'minmax(15rem, 8fr)',
  position: 'minmax(15rem, 4fr)',
  notes: 'minmax(15rem, 4fr)',
  name_of_legal_entity: 'minmax(15rem, 8fr)',
  mail_address: 'minmax(15rem, 8fr)',
  code_main: 'minmax(15rem, 4fr)',
  date_of_state_registration: 'minmax(15rem, 4fr)',
  registration_date: 'minmax(15rem, 4fr)',
  activity_type: 'minmax(15rem, 4fr)', //==??
  type_of_activity: 'minmax(15rem, 4fr)',
  region_name: 'minmax(15rem, 4fr)',
  organization_name: 'minmax(15rem, 8fr)',
  full_name: 'minmax(15rem, 8fr)',
  working_phone_number: 'minmax(15rem, 4fr)',
  phone: 'minmax(15rem, 4fr)',
  phone_number: 'minmax(15rem, 4fr)',
  date_of_voting: 'minmax(15rem, 4fr)',
  signed_at: 'minmax(15rem, 4fr)',
  vote: 'minmax(15rem, 5fr)',
  settlement: 'minmax(30rem, 5fr)',
  code_edrpou: 'minmax(5rem, 5fr)',
  type: 'minmax(3rem, 5fr)',
  state: 'minmax(30rem, 5fr)',
  author: 'minmax(30rem, 4fr)',
  title: 'minmax(40rem, 4fr)',
  created_at: 'minmax(3rem, 4fr)',
  updated_at: 'minmax(3rem, 4fr)',
  tourism_type: 'minmax(3rem, 4fr)',
  username: 'minmax(40rem, 4fr)',
  period: 'minmax(40rem, 4fr)',
  action: 'minmax(30rem, 3.8fr)',
  date_time: 'minmax(15rem, 1.1fr)',
  ip: 'minmax(15rem, 1.1fr)',
  star_category: 'minmax(10rem, 4fr)',
  research_file_type: 'minmax(3rem, 4fr)',
  certification_agency_full_name: 'minmax(15rem, 8fr)'
};
