<template>
  <div v-click-outside="closeTooltipHandler" class="table__menu">
    <button v-if="searchResults" class="table__btn">
      <svg-icon name="eye-closed" class="table__menu-ico" />
      <!-- <span class="table__menu-text">
        {{ $t('table.see_more') }}
      </span> -->
    </button>

    <button
      v-else
      class="table__btn table__btn_menu"
      :class="{ 'table__btn_active-menu': isActiveMenu }"
      @click.stop="isActiveMenu = !isActiveMenu"
    >
      <svg-icon name="dots" class="table__menu-ico" />
    </button>
    <TableTooltip
      v-if="isActiveMenu"
      :item="item"
      class="table__tooltip"
      @closeTooltip="closeTooltipHandler"
    />
  </div>
</template>

<script>
import TableTooltip from '@/elements/TableTooltip.vue';

export default {
  components: {
    TableTooltip,
  },
  props: {
    item: {
      type: Object,
      require,
    },
    searchResults: {
      type: Boolean,
    },
  },
  data() {
    return {
      isActiveMenu: false,
    };
  },

  methods: {
    closeTooltipHandler() {
      this.isActiveMenu = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.table__menu
  position: relative
  transition: all 0.3s

.table__menu-text
  display: inline-block
  position: relative
  transition: all 0.3s
  padding-left: 5px

  &::after
    width: 100%
    height: 1px
    position: absolute
    bottom: 0
    left: 0
    background: $black

.table__btn
  font-size: 14px
  transition: all 0.3s
  color: $gray
  &:hover
    transition: all 0.3s
    .table__menu-text
      color: $black
      &::after
        content: ""
    .table__menu-ico
      fill: $black

.table__btn_menu
  width: 40px
  height: 40px
  border-radius: 50%
  text-align: center
  @include s
    position: absolute
    top: 20px
    right: 10px

.table__btn_menu:hover, .table__btn_active-menu
  background: #F8F8F8

.table__menu-ico
  width: 18px
  height: 10px
  // margin-right: 5px
  // transition: all 0.3s
.table__menu-ico.admin
  width: 24px
  height: 16px
  opacity: 0.4

.table__menu-ico
  &:hover
    opacity: 1

.table__tooltip // to global stylesheet
  background: $white
  color: $black
  opacity: 1 !important
  border: 1px solid #E6EEF2
  box-shadow: 0px 0px 10px rgba(38, 55, 76, 0.2)
</style>
