<template>
  <div class="tooltip__wrapper">
    <ul class="custom-tooltip">
      <li
        v-for="el in curMenu"
        :key="el.name"
        
        @click.stop="tooltipHandler(el)"
      >
        <div class="custom-tooltip__el">{{ $t(`tooltip.${el.name}`) }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setToLS } from '@/library/helpers';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    ...mapGetters(['getTableModel', 'getSelectedCommissionMember']),

    curMenu() {
      // const data = !!this.item.review_status
      //   ? this.getTableModel[this.$route.name].tooltip[this.item.review_status]
      //   : this.getTableModel[this.$route.name].tooltip;
      let data = null;

      if (!!this.item.review_status) {
        data =
          this.getTableModel[this.$route.name].tooltip[this.item.review_status];
      } else {
        let res = [];
        this.getTableModel[this.$route.name].tooltip.map((el) => {
          if ('isExist' in el) {
            if (el.isExist === this.item.isExist) {
              res = [...res, el];
            }
          } else if (
            !!this.getSelectedCommissionMember &&
            this.getSelectedCommissionMember?.name === 'Presiding'
          ) {
            res = res = [...res, el].filter(
              (el) => el.name !== 'edit' && !el.isBlock
            );
          } else {
            res = [...res, el];
          }
        });
        data = res;
      }

      const items = []

      data.forEach(el => {
        if (!!el?.isBlock) {
          // return (el.name = !this.item.is_active ? 'block' : 'unblock');
          items.push({ ...el, name: this.item.is_active ? 'block' : 'unblock' });
        } else {
          if(el.name === 'downloadPDF') {
            if(this.item.archive) {
              items.push(el);
            }
          } else {
            items.push(el);
          }
        }
      });

      return items;
    },
  },

  methods: {
    tooltipHandler(obj) {
      const { name, type_action, isRouting, handler, path } = obj;

      let params_data = {
        id: this.item.uuid,
        activity: type_action,
      };

      if ('props' in this.item) {
        params_data = { ...params_data, ...this.item.props };
      }

      isRouting &&
        this.$router.push({
          name: !!path ? path : this.getTableModel[this.$route.name].path_name,
          params: params_data,
        });

      if (!!handler) {
        if ('is_active' in this.item) {
          this.$store.dispatch(handler, {
            is_active: !this.item.is_active,
            uuid: this.item.uuid,
          });
        } else if (name === 'downloadPDF') {
          this.$store.dispatch(handler, this.item.archive.original_url);
        } else {
          this.$store.dispatch(handler, this.item.uuid);
        }
      }

      this.$emit('closeTooltip');
    },
  },
};
</script>

<style lang="sass" scoped>
.tooltip__wrapper
  position: absolute
  border-radius: 12px
  right: 20px
  top: 15px
  background: #fff
  z-index: 900

.custom-tooltip
  color: $black
  border-radius: 12px
  box-shadow: 0px 0px 10px rgba(38, 55, 76, 0.2)
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  padding: 4px 0
  overflow: hidden

  li
    display: block

.custom-tooltip__el
  display: inline-block
  padding: 8px 24px
  line-height: 22px
  cursor: pointer
  white-space: nowrap
  width: 100%

.custom-tooltip__el:hover
  background: #F9F9F9
</style>
