<template>
  <div class="pagination pagination__container">
    <v-pagination
      v-model="currentPageNumber"
      :length="this.getPageQty"
      :total-visible="7"
      class="pagination__counter"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    pageQty: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getPageQty', 'getCurrentPage']),

    currentPageNumber: {
      get() {
        return this.getCurrentPage;
      },
      set(val) {
        this.$store.commit('SET_TABLE_SORT_DATA', { page: val });
        this.$emit('detectedPageValue');
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.pagination__container
  display: flex
  justify-content: flex-end
  margin-top: 50px
</style>
