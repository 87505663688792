<template>
  <div class="table-date table-date__wrapper">
    <div class="table-date__item">
      {{ getCurrentTimeZoneDate }}
    </div>
    <div class="table-date__item table-date_time">
      {{ getCurrentTimeZoneTime }}
    </div>
  </div>
</template>

<script>
import { convertDateToCurrentTimeZone } from '@/library/time';
export default {
  props: {
    date: {
      type: String,
      default: null
    }
  },

  data() {
    return {}
  },

  computed: {
    getCurrentTimeZoneDate() {
      return !!this.date ? convertDateToCurrentTimeZone(this.date).date : this.date;
    },
    getCurrentTimeZoneTime() {
      return !!this.date ? convertDateToCurrentTimeZone(this.date).time : this.date;
    },
  }
}
</script>

<style lang="sass" scoped>
.table-date__wrapper
  flex-direction: column

.table-date_time
  font-size: 12px
  font-weight: 400
  color: $gray
</style>