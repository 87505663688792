<template>
  <div class="sort sort__wrapper" @click.self="titleHandler">
    <slot></slot>
    <div class="arrows arrows__wrapper">
      <div
        class="arrows__ico arrows__ico_top"
        :class="{ isActive: sortData.type === 'up' }"
        @click="sortData.type = 'up'"
      >
        <svg-icon name="arrow" />
      </div>
      <div
        class="arrows__ico arrows__ico_btm"
        :class="{ isActive: sortData.type === 'dwn' }"
        @click="sortData.type = 'dwn'"
      >
        <svg-icon name="arrow" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [String],
      default: () => {},
    },
  },
  data() {
    return {
      sortBy: '',
      sortData: {
        item: this.item,
        type: 'dwn',
      },
    };
  },

  watch: {
    sortData: {
      handler(obj) {
        this.$emit('sort', obj);
      },
      deep: true,
    },
  },

  methods: {
    titleHandler() {
      if (this.sortData.type) {
        this.sortData.type = this.sortData.type === 'up' ? 'dwn' : 'up';
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.sort__wrapper
  display: flex
  align-items: center
  cursor: pointer

.arrows__ico
  cursor: pointer
  display: flex
  height: 14px
  width: 20px
  justify-content: center

  .icon-arrow
    width: 8px
    height: 5px
    fill: $gray
    transition: all 0.15s

  &:hover, &.isActive
    .icon-arrow
      fill: $black
      transition: all 0.3s

.arrows__ico_top
  align-items: flex-end
  padding-bottom: 1.5px

.arrows__ico_btm
  align-items: flex-start
  .icon-arrow
    transform: rotate(-180deg)
</style>
